import {useContext, useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { getMenuItems } from "../api/rifService";
import NavBarMobile from "./NavBarMobile";
import LogoImg from "../images/NavBar/logo.png";
import SkybraryLogo from "../images/short-logo-skybrary-school.png"
import { isCleverTheme } from "../utils/helpers";
import CleverProvider  from '../context/clever'
import UserProvider from "../context/user";

const NavBar = () => {
  const CONSUMER_DOMAIN = process.env.REACT_APP_CONSUMER_DOMAIN;
  const [navBarContent, setNavBarContent] = useState<any>(null);
  const [openSkybrary, setOpenSkybrary] = useState(false);
  const [openSkybrarySchool, setOpenSkybrarySchool] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(false);

  const userContext = useContext(UserProvider)
  const cleverContext = useContext(CleverProvider)

  const [showAccountTypeModal, setShowAccountTypeModal] = useState(false);

  const handleClose = () => setShowAccountTypeModal(false);
  const handleShow = () => setShowAccountTypeModal(true);

  useEffect(() => {
    getMenuItems().then((response: any) => {
      setNavBarContent(response);
    });
  }, []);

  let isCleverThemed = isCleverTheme(userContext,cleverContext);

  return (
    <div className="menu">
      <Navbar
        bg="light"
        expand="lg"
        fixed="top"
        className="navbar-custom justify-content-center"
      >
        <div className="links d-flex justify-content-between">
          <div>
            <Navbar.Brand href="/">
              <img
                src={LogoImg}
                className="rif-logo d-inline-block align-top"
                alt="RIF logo"
              />
              {isCleverThemed && (
                <img
                  src={SkybraryLogo}
                  className="rif-logo skybrary-logo d-inline-block align-top"
                  alt="SKYBRARY logo"
                />
              )}
            </Navbar.Brand>
          </div>

          {navBarContent && (
            <div className="d-flex align-items-center">
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav className="dropdowns">
                  <div
                    className="skybrary"
                    onMouseLeave={() => setOpenSkybrary(false)}
                    onMouseEnter={() => setOpenSkybrary(true)}
                  >
                    <NavDropdown
                      title={navBarContent[0]?.link.title}
                      id="skybrary"
                      show={openSkybrary}
                    >
                      {navBarContent[0]?.subtree.map(
                        (element: any, index: number) => {
                          return (
                            <div key={index + element.link.title} className="dropdown-item-container">
                              <NavDropdown.Item
                                href={
                                  element.link.options.external
                                    ? element.link.url
                                    : CONSUMER_DOMAIN + element.link.url
                                }
                                className="text-center"
                              >
                                <div className="px-2">
                                  {element.link.title}
                                </div>
                              </NavDropdown.Item>
                            </div>
                          );
                        }
                      )}
                    </NavDropdown>
                  </div>
                  <div
                    className="skybrary-school"
                    onMouseLeave={() => setOpenSkybrarySchool(false)}
                    onMouseEnter={() => setOpenSkybrarySchool(true)}
                  >
                    <NavDropdown
                      title={navBarContent[1]?.link.title}
                      id="skybrary-school"
                      show={openSkybrarySchool}
                    >
                      {navBarContent[1]?.subtree.map(
                        (element: any, index: number) => {
                          return (
                            <div key={index + element.link.title} className="dropdown-item-container">
                              <NavDropdown.Item
                                href={
                                  element.link.options.external
                                    ? element.link.url
                                    : CONSUMER_DOMAIN + element.link.url
                                }
                                className="text-center"
                              >
                                <div className="px-2">
                                  {element.link.title}
                                </div>
                              </NavDropdown.Item>
                            </div>
                          );
                        }
                      )}
                    </NavDropdown>
                  </div>
                  <div
                    className="faq"
                    onMouseLeave={() => setOpenFAQ(false)}
                    onMouseEnter={() => setOpenFAQ(true)}
                  >
                    <NavDropdown
                      title={navBarContent[2]?.link.title}
                      id="faq"
                      show={openFAQ}
                    >
                      {navBarContent[2]?.subtree.map(
                        (element: any, index: number) => {
                          return (
                            <div key={index + element.link.title} className="dropdown-item-container">
                              <NavDropdown.Item
                                href={
                                  element.link.options.external
                                    ? element.link.url
                                    : CONSUMER_DOMAIN + element.link.url
                                }
                                className="text-center"
                              >
                                <div className="px-2">
                                  {element.link.title}
                                </div>
                              </NavDropdown.Item>
                            </div>
                          );
                        }
                      )}
                    </NavDropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
              <button
                className={`button ${isCleverThemed ? `btn-moderate-green` : `btn-mojo`} sign-in-size`}
                onClick={handleShow}
              >
                {navBarContent[3]?.link.title}
              </button>
            </div>
          )}
        </div>
      </Navbar>
      <Modal
        className="nav-modal"
        show={showAccountTypeModal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div id="signinText"></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="choice-family">
                  <a href={CONSUMER_DOMAIN + "/user/sign-in"}>
                    <div className="container"></div>
                  </a>
                </td>
                <td className="choice-student">
                  <a href="/student/classroom/access">
                    <div className="container"></div>
                  </a>
                </td>
                <td className="choice-teacher">
                  <a href="/user">
                    <div className="container"></div>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <NavBarMobile
        consumerDomain={CONSUMER_DOMAIN}
        navBarContent={navBarContent}
      />
    </div>
  );
};

export default NavBar;
