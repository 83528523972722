import { createContext, useContext, useEffect, useState } from "react";

export const FormPurchaseContext = createContext({
  firstStep: {},
  secondStep: {},
  setSecondStep: (data) => {},
  thirdStep: {},
  setThirdStep: (data) => {},
  registerData: {},
  setRegisterData: (data) => {},
  renewals: [],
  setRenewals: (data) => {},
});


export const useFormPurchase = () => useContext(FormPurchaseContext);

export const FormPurchaseProvider = ({ children }) => {

  const getStorageValue = (key) => {
    return JSON.parse(sessionStorage.getItem(key))
  }

  const setStorageValue = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  const [secondStep, setSecondStep] = useState(getStorageValue('schoolInfo'));

  const [thirdStep, setThirdStep] = useState(getStorageValue('orderDetail'));

  const [step, setStep] = useState(1)

  const [registerData, setRegisterData] = useState(getStorageValue('registerData'));

  const [renewals, setRenewals] = useState(getStorageValue('renewals'))

  const contextValue = {
    secondStep,
    setSecondStep,
    thirdStep,
    setThirdStep,
    registerData,
    setRegisterData,
    renewals,
    setRenewals,
  }

  useEffect(() => {
    setStorageValue('schoolInfo', secondStep);
    setStorageValue('orderDetail', thirdStep);
    setStorageValue('registerData', registerData);
    setStorageValue('renewals', renewals);
  }, [secondStep, thirdStep, registerData, renewals])


  return (
    <FormPurchaseContext.Provider value={contextValue}>
      {children}
    </ FormPurchaseContext.Provider>
  );
};

// Steps for multisteps forms

export const formSteps = {
  purchase: [
    {
      step:1,
      text: "Create Account",
      link: "/"
    },
    {
      step:2,
      text: "Tell Us About Yourself",
      link: "/"
    },
    {
      step:3,
      text: "Purchase Info",
      link: "/"
    },
  ],
  try_it_free: [
    {
      step:1,
      text: "Create Account",
      link: "/"
    },
    {
      step:2,
      text: "Tell Us About Yourself",
      link: "/"
    },
  ],
  gift: [
    {
      step:1,
      text: "Your Information",
      link: "/"
    },
    {
      step:2,
      text: "Order",
      link: "/"
    },
    {
      step:3,
      text: "Payment",
      link: "/"
    },
  ],
  redeem: [
    {
      step: 1,
      text: "Subscription Code",
      link: "/"
    },
    {
      step: 2,
      text: "Your Account",
      link: "/"
    },
    {
      step: 3,
      text: "School Information",
      link: "/"
    },
  ],
  redeem_logged: [
    {
      step: 1,
      text: "Subscription Code",
      link: "/"
    },
    {
      step: 2,
      text: "Your Account",
      link: "/"
    },
    {
      step: 3,
      text: "Subscriptions",
      link: "/"
    },
  ],
  try_it_free_purchase: [
    {
      step: 1,
      text: "Purchase Info",
      link: "/",
    },
    {
      step: 2,
      text: "Payment",
      link: "/",
    },
    {
      step: 3,
      text: "Done",
      link: "/",
    }
  ],
  try_it_free_purchase_email: [
    {
      step: 1,
      text: "Tell Us About Yourself",
      link: "/",
    },
    {
      step: 2,
      text: "Payment",
      link: "/",
    },
    {
      step: 3,
      text: "Done",
      link: "/",
    }
  ],
};
