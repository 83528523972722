import { Link } from "react-router-dom";

const Header = (props: {
  children: any;
  title: string;
  subTitle: string;
  from: string;
}) => {
  return (
    <div className="header">
      <h1 className="title">
        {props.children}
        {props.from === "student" || props.from === "teacher" ? (
          <em> {props.title}</em>
        ) : (
          ` To Skybrary School!`
        )}
      </h1>
      <div className="subtitle">{props.subTitle}</div>
      {props.from === "teacher" && (
        <Link
          className="back-to-dashboard-button"
          to="/teachers/dashboard"
        >{`<< Back to Dashboard`}</Link>
      )}
      {
        props.from === "parent" && (
          <Link
            className="back-to-dashboard-button"
            to="/parent/dashboard">
              {`<< Back to Dashboard`}
          </Link>
        ) 
      }
    </div>
  );
};

export default Header;
