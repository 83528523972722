import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";
import { SKYBRARY_CLEVER_TOKEN, SKYBRARY_USER_TOKEN } from "../utils/constants";
import { useContext, useEffect } from "react";
import UserContext from "../context/user";

function getUrlDependsOnUserType(type: string) {
  return type && type.includes("admin")
    ? "/adm/dashboard"
    : "/teachers/dashboard";
}

const Unauthorized = () => {
  const cookies = new Cookies();
  const cleverToken = cookies.get(SKYBRARY_CLEVER_TOKEN);
  const userToken = cookies.get(SKYBRARY_USER_TOKEN);
  const userContext = useContext(UserContext);

  useEffect(() => {
    document.title = "Unauthorized | Reading Is Fundamental";
  }, []);

  let redirectTo = "/user";
  if (userToken) {
    const { type } = userContext?.userData; //@TODO: fix userData types.
    const redirectTo = getUrlDependsOnUserType(type);
  } else {
    userContext.logout();
  }

  return (
    <div className="unauthorized-page mt-5">
      <div className="page-content" />
      <h3 className="page-text mb-5">
        You are not authorized to access this page.
      </h3>
      <div className="text-center">
        <Link to={redirectTo} className="btn btn-table-di-serria">
          {!userToken ? "Log In" : "<< Back To Dashboard"}
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
