import { Link } from "react-router-dom";

const Student = (props: {
  to: string;
  username: string;
  hash: string | undefined;
  parentEmail: string;
  parentId: string;
  studentId: string;
  teacherId: string;
  userType: string;
  name: string;
  lastName: string;
}) => {
  const studentsInfo = {
    hash: props.hash,
    username: props.username,
    parentEmail: props.parentEmail,
    parentId: props.parentId,
    studentId: props.studentId,
    teacherId: props.teacherId,
    userType: props.userType,
    name: props.name,
    lastName: props.lastName,
  }
  
  return (
    <div className="students-list">
      <Link
        to={props.to}
        state={{ studentsInfo }}
        className="student"
      >
        {props.username}
      </Link>
    </div>
  );
};

export default Student;
