import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const Steeper = (props: any) => {
  return (
    <Breadcrumb className={`col p-0 steps-${props.steeperType}-list`}>
      {props.steps.map((step: any, index: number) => { 
        return (
          <BreadcrumbItem linkProps={{to: step.link}} className={`step ${(step.step === props.activeStep) && 'active' || 'inactive'}`}  key={index} linkAs={Link}>
            {step.text}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  );
};

export default Steeper;
