import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonMenu from "../components/ButtonMenu";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar";
import GiftContext from "../context/Forms/giftContext";
import CleverContext from "../context/clever";
import UserContext from "../context/user";
import { isCleverTheme } from "../utils/helpers";

const NotFound = () => {
  const giftContext = useContext(GiftContext);
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const [prevPage, setPrevPage] = useState({
    status: false,
    page: "",
    url: "",
  });

  useEffect(() => {
    document.title = "Page not found | Reading Is Fundamental";
    if (userContext.token != null) {
      if (giftContext.prevUrl === "/adm/dashboard") {
        setPrevPage({
          status: true,
          page: "admin",
          url: "/adm/dashboard",
        });
      } else if (giftContext.prevUrl === "/teachers/dashboard") {
        setPrevPage({
          status: true,
          page: "teacher",
          url: "/teachers/dashboard",
        });
      }
    }
  }, []);

  return (
    <div className="not-found-page">
      <NavBar />
      <div className="pt-5">
        <ButtonMenu />
      </div>
      <div>
        <h1 className="text-center title">Page not found</h1>
        <div className="text-center">
          <Link
            to={prevPage.status ? prevPage.url : "/"}
            className={`home-button ${isCleverTheme(userContext, cleverContext) ? 'btn-sky btn-moderate-green btn-sky-lg' : 'btn btn-brandy-punch '}`}
          >{prevPage.status ? `GO TO YOUR DASHBOARD` : `GO TO HOME`}
          </Link>
        </div>
      </div>
      <Footer/>
    </div>
  );

};
export default NotFound;
