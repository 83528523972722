import { useEffect } from "react";
import { Cookies } from "react-cookie";
import {
  SKYBRARY_TOKEN_EXPIRES_IN,
  SKYBRARY_USER_REFRESH_TOKEN,
  SKYBRARY_USER_TOKEN,
} from "@utils/constants";

const RedirectToConsumer = () => {
  const cookies = new Cookies();

  const cookieParams = {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  };

  useEffect(() => {
    if (cookies.get(SKYBRARY_USER_TOKEN) === undefined) {
      localStorage.clear();
      sessionStorage.clear();
      cookies.remove(SKYBRARY_USER_TOKEN, cookieParams);
      cookies.remove(SKYBRARY_TOKEN_EXPIRES_IN, cookieParams);
      cookies.remove(SKYBRARY_USER_REFRESH_TOKEN, cookieParams);
      cookies.remove("Drupal.visitor.rrkuser", cookieParams);
    }
    window.location.replace("https://www.skybrary.org/school");
  }, []);

  return <></>;
};

export default RedirectToConsumer;
