import React, { createContext, useState } from "react";

const GiftContext = createContext({
  prevUrl: '',
  firstStep: {},
  secondStep: {},
  setForms: (props) => {},
  setLastUrl: (props) => {},
});

export const GiftProvider = (props) => {
  let initialLastUrl;
  if (localStorage.getItem('lastURL')) {
    initialLastUrl = localStorage.getItem('lastURL');
  }

  const [previousUrl] = useState (initialLastUrl);

  const firstStepData = JSON.parse(localStorage.getItem("firstStepData"));
  let initialFirstStep;

  if (firstStepData) {
    initialFirstStep = firstStepData;
  }

  const [firstStepInfo, setFirstStepInfo] = useState(initialFirstStep);

  const secondStepData = JSON.parse(localStorage.getItem("secondStepData"));
  let initialSecondStep;

  if (secondStepData) {
    initialSecondStep = secondStepData;
  }

  const [secondStepInfo, setSecondStepInfo] = useState(initialSecondStep);

  const setFormsData = (props) => {
    if (props.form === 1) {
      setFirstStepInfo({
        buyerFirstName: props.values.buyerFirstName,
        buyerLastName: props.values.buyerLastName,
        buyerEmail: props.values.buyerEmail,
        recipientFirstName: props.values.recipientFirstName,
        recipientLastName: props.values.recipientLastName,
        recipientEmail: props.values.recipientEmail,
      });
      localStorage.setItem("firstStepData", JSON.stringify(props.values));
    }

    if (props.form === 2) {
      setSecondStepInfo({
        classroomPlan: props.values.classroomPlan,
        classroomPlanPrice: 179,
        schoolPlan: props.values.schoolPlan,
        schoolPlanPrice: 1450,
        orderTotal: props.orderTotal,
        promoCode: props.promoCode,
        discountCode: props.discountCode,
      });
      localStorage.setItem("secondStepData", JSON.stringify(props.values));
    }
  };

  const setLastUrl = (props) => {
    localStorage.setItem('lastURL', props)
  }

  const contextValue = {
    prevUrl: previousUrl,
    firstStep: firstStepInfo,
    secondStep: secondStepInfo,
    setForms: setFormsData,
    setLastUrl: setLastUrl,
  };

  return (
    <GiftContext.Provider value={contextValue}>
      {props.children}
    </GiftContext.Provider>
  );
};

export default GiftContext;
