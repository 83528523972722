import Student from "./Student";
import Header from "./Header";
import { useEffect, useState } from "react";
import TextSuccess from "../TextSuccess";

const  SignInClassroom = (props: {
  welcomeName: string;
  students: any;
  hash: string | undefined;
  from: string;
  classInfo: object;
}) => {
  const studentsList: any[] = props.students;

  const [students, setStudents] = useState();
  const [subTitle, setSubTitle] = useState("");

  const mappedData = studentsList.map((student, index) => {
    let studentToken = encodeURIComponent(
      btoa(JSON.stringify(student.rrkStudentId))
    );

    return (
      <Student
        key={index}
        username={student.userName}
        to={`/sigin/login/${studentToken}`}
        hash={props.hash}
        parentEmail={student.parentEmail}
        parentId={student.parentId}
        studentId={student.rrkStudentId}
        teacherId={student.rrkTeacherId}
        userType={props.from}
        name={student.name}
        lastName={student.lastName}
      />
    );
  });

  useEffect(() => {
    document.title = `Welcome to ${props.welcomeName} | Reading Is Fundamental`;
    if (props.from === "parent") {
      setSubTitle("Click in Your class to Open Skybrary");
      document.title = `Welcome to Skybrary School! | Reading Is Fundamental`;
    } else if (props.from === "student" || props.from === "teacher") {
      setSubTitle("Click on your name to open Skybrary");
    }

    if (props.classInfo.isExpired) {
      setStudents(<div className="child-expired mt-5">This classroom has expired.</div>);
    } else {
      if (!props.classInfo.validHash) {
        setStudents(<div className="child-expired mt-5">Classroom link not found.</div>);
      } else {
        if (props.students.length === 0) {
          setStudents(<div className="child-expired mt-5">The classroom has no childs.</div>);
          setSubTitle("")
        } else {
          setStudents(mappedData);
        }
      }
    }
  }, []);
    
  return (
    <div className="parents-container">
      {props.from === "teacher" && (
        <TextSuccess text="Don't forget to bookmark this to make this easier for your students to sign-in easily" />
      )}
      <Header
        title={`to ${props.welcomeName}`}
        subTitle={subTitle}
        from={props.from}
      >
        Welcome
      </Header>
      <div className="body">{students}</div>
    </div>
  );
};

export default SignInClassroom;
