import { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

import HamburgerIcon from "../images/NavBar/hamburger-icon.png";
import HamburgerClose from "../images/NavBar/hamburger-close.png";
import LogoImg from "../images/NavBar/logo.png";
import SignInTitle from "../images/NavBar/mobile-images/modal-title.png";
import FamilyImg from "../images/NavBar/mobile-images/family_select.png";
import StudentsImg from "../images/NavBar/mobile-images/students_select.png";
import TeachersImg from "../images/NavBar/mobile-images/teachers_select.png";

interface NavBarMobileProps {
  consumerDomain: string | undefined;
  navBarContent: any;
}

const NavBarMobile = ({ consumerDomain, navBarContent }: NavBarMobileProps) => {
  const [unfoldedHamburger, setUnfoldedHamburger] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="only-mobile">
      {!unfoldedHamburger ? (
        <Navbar bg="light" expand="lg" fixed="top" className="hamburger-close">
          <>
            <span>
              <img
                src={HamburgerIcon}
                className="hamburger-icon d-inline-block align-top"
                alt="hamburger icon"
                onClick={() => setUnfoldedHamburger(true)}
              />
            </span>
            <Navbar.Brand href="/">
              <img src={LogoImg} alt="Skybrary logo" className="rif-logo" />
            </Navbar.Brand>
          </>
        </Navbar>
      ) : (
        <>
          {navBarContent && (
            <Navbar
              bg="light"
              expand="lg"
              fixed="top"
              className="hamburger-open"
            >
              <span>
                <img
                  src={HamburgerClose}
                  className="hamburger-icon d-inline-block align-top"
                  alt="hamburger icon"
                  onClick={() => setUnfoldedHamburger(false)}
                />
              </span>

              <Navbar.Brand href="/" className="mx-auto">
                <img src={LogoImg} alt="Skybrary logo" className="rif-logo" />
              </Navbar.Brand>
              <Navbar.Brand
                className="nav-mobile-button row align-items-center text-center"
                onClick={handleShow}
              >
                <span>{navBarContent[3]?.link.title}</span>
              </Navbar.Brand>
              <Container>
                <Accordion>
                  <div className="skybrary">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {navBarContent[0]?.link.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup variant="flush">
                          {navBarContent[0]?.subtree.map(
                            (element: any, index: number) => {
                              return (
                                <ListGroup.Item
                                  action
                                  href={
                                    element.link.options.external
                                      ? element.link.url
                                      : consumerDomain + element.link.url
                                  }
                                  key={index + element.link.title}
                                >
                                  {element.link.title}
                                </ListGroup.Item>
                              );
                            }
                          )}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="skybrary-school">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {navBarContent[1]?.link.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup variant="flush">
                          {navBarContent[1]?.subtree.map(
                            (element: any, index: number) => {
                              return (
                                <ListGroup.Item
                                  action
                                  href={
                                    element.link.options.external
                                      ? element.link.url
                                      : consumerDomain + element.link.url
                                  }
                                  key={index + element.link.title}
                                >
                                  {element.link.title}
                                </ListGroup.Item>
                              );
                            }
                          )}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="faq">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {navBarContent[2]?.link.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup variant="flush">
                          {navBarContent[2]?.subtree.map(
                            (element: any, index: number) => {
                              return (
                                <ListGroup.Item
                                  action
                                  href={
                                    element.link.options.external
                                      ? element.link.url
                                      : consumerDomain + element.link.url
                                  }
                                  key={index + element.link.title}
                                >
                                  {element.link.title}
                                </ListGroup.Item>
                              );
                            }
                          )}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              </Container>
            </Navbar>
          )}

          <Offcanvas
            show={show}
            onHide={handleClose}
            backdrop="static"
            className="nav-offcanvas"
          >
            <Offcanvas.Header closeButton>
              <div className="sign-in-text mx-auto">
                <img
                  className="img-fluid"
                  src={SignInTitle}
                  alt="sign in title"
                />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Table borderless={true}>
                <tbody>
                  <tr>
                    <td className="choice-family">
                      <a href={consumerDomain + "/user/sign-in"}>
                        <div className="container">
                          <img
                            className="img-fluid"
                            src={FamilyImg}
                            alt="family option"
                          />
                        </div>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="choice-student">
                      <a href="/student/classroom/access">
                        <div className="container">
                          <img
                            className="img-fluid"
                            src={StudentsImg}
                            alt="students option"
                          />
                        </div>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="choice-teacher">
                      <a href="/user">
                        <div className="container">
                          <img
                            className="img-fluid"
                            src={TeachersImg}
                            alt="teachers option"
                          />
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
};

export default NavBarMobile;
