const TextError = (props: { wrapper: string; text: {} | null | undefined; }) => {
  let wrapper = <div></div>;
  if (props.wrapper === "div") {
    wrapper = <div className="text-error p-2 ps-5">{props.text}</div>;
  } else {
    if (props.wrapper === "li") {
      wrapper = <li className="text-error">{props.text}</li>;
    }
  }

  return wrapper;
};

export default TextError;