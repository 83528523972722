import { sendEvent } from "@api/googleAnalyticsService";
import { getSchoolSubscriptions } from "@api/rifService";
import { useCleverContext } from "@context/clever";
import GiftContext from "@context/Forms/giftContext";
import { FormPurchaseContext } from "@context/Forms/purchaseContext";
import { useUserContext } from "@context/user";
import successImg from "@images/success.png";
import { BACK_URL, registerText } from "@utils/constants";
import html2pdf from "html2pdf.js";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import OrderFormPdf from "./Forms/purchase/OrderFormPdf";
import Header from "./header/Header";
import { useThemeContext } from "@providers/ThemeProvider";

const SuccessComponent = (props: { page: string; upgradeTrial: boolean }) => {
  const orderContext: any = useContext(FormPurchaseContext);
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const { setCustomTheme } = useThemeContext()
  const giftContext: any = useContext(GiftContext);
  const pdfRef = useRef(null);

  const schoolId = userContext.userData.edu.user.rrkSchoolId;
  const token = userContext.token;
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        await html2pdf().from(html).saveAs("Skybrary-order");
      }
    },
  });

  const isTryItFree = orderContext.thirdStep === null ? true : false;
  let purchaseOrderForm;
  let purchaseInfo;
  let title = registerText[props.page].successTitle;
  let successMsg = registerText[props.page].successMessage;
  let successMsgBottom = registerText[props.page].successMsgBottom;
  let state_token = "";

  const location = useLocation();
  const [orderForm, setOrderForm] = useState(false);

  const [fromTrial, setFromTrial] = useState(false);

  let previousPath = BACK_URL;
  let backButtonText = "<< Back";
  let bottomButton = "SIGN IN";
  let linkTo = "/user";

  if (props.page === "redeem") {
    if (cleverContext.isCleverUser()) {
      bottomButton = "DONE";
      linkTo = "/adm/dashboard";
      document.title = "Success | Reading Is Fundamental";
    } else {
      bottomButton = "SIGN IN";
      linkTo = "/user";
      document.title = "Success | Reading Is Fundamental";
    }
  }

  if (props.page === "gift") {
    bottomButton = "DONE";
    linkTo = "#";
    document.title = "Success | Reading Is Fundamental";
  }

  if (props.page === "try_it_free" || props.page === "purchase") {
    document.title = "You Are Done! | Reading Is Fundamental";
    linkTo = "/user";
  }

  const sendToGoogleAnalytics = () => {
    let plans = [];
    if (!isTryItFree) {
      let contextPaymentData =
        props.page === "gift" ? giftContext.secondStep : orderContext.thirdStep;
      if (Number(contextPaymentData.classroomPlan) > 0) {
        plans.push({
          item_id: "Classroom Plan",
          item_name: "Classroom Plan",
          coupon: contextPaymentData.promoCode,
          discount: contextPaymentData.discountCode,
          price: contextPaymentData.classroomPlanPrice,
          currency: "USD",
          quantity: contextPaymentData.classroomPlan,
        });
      }

      if (Number(contextPaymentData.schoolPlan) > 0) {
        plans.push({
          item_id: "School Plan",
          item_name: "School Plan",
          coupon: "",
          discount: "",
          price: contextPaymentData.schoolPlanPrice,
          currency: "USD",
          quantity: contextPaymentData.schoolPlan,
        });
      }

      sendEvent({
        event: "purchase",
        eventProps: {
          coupon: contextPaymentData.promoCode,
          currency: "USD",
          items: plans,
          transaction_id: location.state.orderForm
            ? location.state.data.order.orderId
            : orderContext.thirdStep.orderId,
          value: contextPaymentData.orderTotal,
        },
      });
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.fromTrial) {
        setFromTrial(true);
      }

      if (location.state.orderForm) {
        setOrderForm(true);
      }
    }
    sendToGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (orderForm) {
      handlePrint();
    }
  }, [orderForm]);

  const handleButton = () => {
    let userLoggedIn = userContext.isLoggedIn;
    let isTrial = userContext.isTrial;
    if (userLoggedIn && !isTrial) {
      let teacherId = null;
      if (userContext.userData.type === "edu-teacher") {
        teacherId = userContext.userData.edu.user.rrkEduUserId;
      }
      getSchoolSubscriptions(requestConfig, schoolId, teacherId).then(
        (schoolSubscription) => {
          userContext.saveSubscriptions(
            schoolSubscription,
            userContext.userData.edu.user,
          );
        },
      );
    }
    if (props.page === "gift") {
      window.location.replace(BACK_URL);
    }

    if (linkTo.includes("/user")) {
      setCustomTheme("sky-theme")
    }
  };

  if (giftContext.prevUrl) {
    previousPath = giftContext.prevUrl;
    backButtonText = "<< Back";
    bottomButton = "Done";
  }

  if (fromTrial) {
    userContext.saveSettingPassword(true);
    backButtonText = "";
    previousPath = "";
    linkTo = "/set-password";
    state_token = {
      access_token: location.state?.access_token,
      activateTeacher: false,
    };
  }

  if (props.page !== "try_it_free") {
    if (fromTrial) {
      purchaseInfo = (
        <div>
          <div className="order">Order #{orderContext.thirdStep.orderId}</div>
          <div className="detail">
            <p className="mb-0">
              {giftContext.secondStep.classroomPlan} Classroom Plan(s)
            </p>
            <p>{giftContext.secondStep.schoolPlan} School Plan(s)</p>
          </div>
        </div>
      );
    } else {
      if (props.page !== "redeem") {
        backButtonText = "<< Back";
        if (props.page !== "gift") {
          if (orderContext.thirdStep.isOrderForm) {
            title = registerText[props.page].purchase_order_form.successTitle;
            successMsg =
              registerText[props.page].purchase_order_form.successMessage;
            successMsgBottom =
              registerText[props.page].purchase_order_form.successMsgBottom;
            purchaseOrderForm = (
              <div className="order mb-5">
                <span>
                  Download the Order{" "}
                  <button className="download-link" onClick={handlePrint}>
                    from here
                  </button>
                </span>
                <div className="d-none">
                  <OrderFormPdf refToAsign={pdfRef} />
                </div>
              </div>
            );
          } else {
            purchaseInfo = (
              <div>
                <div className="order">
                  Order #{orderContext.thirdStep.orderId}
                </div>
                <div className="detail">
                  <p className="mb-0">
                    {orderContext.thirdStep.classroomPlan} Classroom Plan(s)
                  </p>
                  <p>{orderContext.thirdStep.schoolPlan} School Plan(s)</p>
                </div>
              </div>
            );
          }
        }
      }
    }
  } else {
    backButtonText = "<< Back";
  }

  if (props.upgradeTrial) {
    previousPath = "/teachers/dashboard";
    backButtonText = "<< Back to Dashboard";
    bottomButton = "Sign In";
  }

  return (
    <>
      <Header
        steps={[]}
        activeStep={1}
        previousPath={previousPath}
        buttonText={backButtonText}
      />

      <div className="successContainer">
        <div className="title my-4">{title}</div>
        <div>
          <img src={successImg} alt="success" />
        </div>
        <div className="message mt-2 mb-4">{successMsg}</div>
        {fromTrial ? (
          purchaseInfo
        ) : (
          <>
            {props.page === "purchase" && orderContext.thirdStep.isOrderForm
              ? purchaseOrderForm
              : purchaseInfo}
          </>
        )}
        <div>
          {props.page !== "gift" && (
            <div className="message mb-4">{successMsgBottom}</div>
          )}
          <Link
            to={linkTo}
            state={state_token}
            className="btn btn-red-damask"
            onClick={() => handleButton()}
          >
            {bottomButton}
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuccessComponent;
