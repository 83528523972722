import SkybraryLogo from "../../images/logo-skybrary-school.png";
import Steeper from "../steeper/Steeper"
import { useNavigate } from "react-router";
import { useContext } from "react";
import UserContext from "../../context/user";

const Header = (props: any) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const fromTrial = userContext.fromTrial;
  const isTrial = userContext.isTrial;

  const handleBack = async () => {
    if (!userContext.loginFlag && !fromTrial && !isTrial) {
      window.location.replace(props.previousPath);
    } else if (userContext.loginFlag && isTrial) {
      navigate(`${props.previousPath}`, {state: {fromTrial: true}});
    } else {
      navigate(props.previousPath);
    }
  }

  return (
    <header className="form-header w-100 p-0 mb-md-2 mb-5">
      <div className="row mx-auto w-100">
        <div className="skybrary-logo w-auto p-0">
          <img className="img" width="130" height="110" src={SkybraryLogo} alt="skybrary-logo"/>
        </div>
        <Steeper steeperType={"form"} steps={props.steps} activeStep={props.activeStep} />
        <div>
          <button className="go-back p-0 align-self-sm-start" onClick={() => handleBack()}>{props.buttonText}</button>
        </div>
      </div>
    </header>

  )
}

export default Header;
