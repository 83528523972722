import Header from "./Header";
import LogInForm from "../../components/Forms/parents/LogInForm";
import { Link, useLocation } from "react-router-dom";
import TextError from "../TextError";
import { useEffect, useState } from "react";
import TextSuccess from "../TextSuccess";

const LogIn = (props: { userToken: string | undefined; data: any }) => {
  const location: any = useLocation();

  const [invalidPassword, setInvalidPassword] = useState({
    show: false,
    message: "",
  });

  let teacher = null;
  const classroomHash = props.data.classroomHash;
  let userType = location.state?.studentsInfo?.userType;
  
  let backLink = `/sigin/classroom/${classroomHash}`
  
  if (props.data !== undefined) {
    if (userType === "teacher") {
      teacher = { teacher: true };
    } else if (userType === "student") {
      teacher = { access: true };
      backLink = `/sigin/classroom/${props.data.classroomHash}`
    } else if (userType === "parent") {
      backLink = `/sigin/parent/${props.data.hash}`
    }
  }

  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    document.title = `Welcome to ${location.state?.studentsInfo?.name} ${location.state?.studentsInfo?.lastName} | Reading Is Fundamental`;
    if (location.state?.studentsInfo?.passwordChanged) {
      setPasswordChanged(true);
    }
  }, []);

  const errorHandler = (newState: any) => {
    setInvalidPassword(newState);
  };

  return (
    <div className="parents-container">
      {passwordChanged && (
        <div className="my-4 password-changed">
          <TextSuccess text={"Password Updated"} />
        </div>
      )}
      {invalidPassword.show && (
        <div className="my-2">
          <TextError wrapper="div" text={invalidPassword.message} />
        </div>
      )}
      <Header
        title={`${location.state?.studentsInfo?.name} ${location.state?.studentsInfo?.lastName}`}
        subTitle="Enter your password"
        from={userType}
      >
        {" "}
        Welcome,{" "}
      </Header>
      <Link
        to={backLink}
        className="back-to-button login"
        state={teacher}
      >{`<< Back to Students`}</Link>
      <div className="body">
        <LogInForm
          userToken={props.userToken}
          showError={errorHandler}
          data={props.data}
        />
      </div>
    </div>
  );
};

export default LogIn;
