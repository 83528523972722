import { Spinner } from "react-bootstrap";

interface LoadingSpinnerProps {}

// A spinner that adapts to it's parent container and places itself in the middle
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        alignItems: "center",
        bottom: "0",
      }}
    >
      <Spinner
        style={{
          zIndex: "1",
        }}
        animation="border"
        variant="primary"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
